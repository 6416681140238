(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAccount.wbCompany.controller.CompleteCsnListsCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAccount.wbCompany')
    .controller('CompleteCsnListsCtrl', CompleteCsnListsCtrl);

  CompleteCsnListsCtrl.$inject = [
    'Company',
    'LoggedUser',
    '$window',
    '$document'
  ];

  function CompleteCsnListsCtrl(Company, LoggedUser, $window, $document) {
    var vm = this;
    var company = LoggedUser.getCompany();
    var fileName = 'teljes-szeriaszamos-lista.xlsx';

    vm.isLoading = false;
    vm.downloadCsnFile = downloadCsnFile;

    function downloadCsnFile() {
      toggleGenerateProcessing();

      Company.generateAndGetCsnFile({companyId: company.id}).$promise
        .then(function (response) {
          downloadFile(response.response);
        })
        .finally(function () {
          toggleGenerateProcessing();
        });
    }

    function toggleGenerateProcessing() {
      vm.isLoading = !vm.isLoading;
    }

    function downloadFile(fileBlob) {
      var elem = $document[0].createElement('a');

      if ($window.navigator.msSaveOrOpenBlob) {
        $window.navigator.msSaveBlob(fileBlob, fileName);
      } else {
        elem.href = $window.URL.createObjectURL(fileBlob);
        elem.download = fileName;
        $document[0].body.appendChild(elem);
        elem.click();
        $document[0].body.removeChild(elem);
      }
    }
  }
})();
